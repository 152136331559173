<template>
    <div>
        <!-- Breadcrumbs -->
        <v-container v-if="smAndUp">
            <v-row>
                <v-col>
                    <v-breadcrumbs
                        :items="breadCrumbs"
                        divider=">"
                    ></v-breadcrumbs>
                </v-col>
            </v-row>
        </v-container>

        <v-container>
            <v-row justify="center" :class="smAndUp ? 'mb-15' : null">
                <v-col cols="auto" align="center">
                    <h1><heading>体験レッスン</heading></h1>
                </v-col>
                <v-col cols="12" align="center">
                    <v-img :src="imageMv"></v-img>
                </v-col>
            </v-row>
        </v-container>

        <v-container>
            <v-row justify="center" :class="smAndUp ? 'mb-15' : null">
                <v-col cols="auto" align="center">
                    <div
                        :class="smAndUp ? 'text-h4 mb-8 ' : 'text-h6'"
                        class="font-weight-thin"
                        v-html="lead"
                    ></div>
                </v-col>
            </v-row>
            <!-- timeline -->
            <v-row justify="center" :class="smAndUp ? 'mb-15' : null">
                <v-col cols="auto" align="center">
                    <div :class="smAndUp ? 'steps' : 'steps-sp'">
                        <p class="under my-4 text-md-h4 font-weight-thin">
                            LINEで予約
                        </p>
                        <v-img max-height="15px" :src="sankaku" contain></v-img>
                        <div>
                            <p
                                class="blue-grey--text my-4 text-md-h4 font-weight-thin"
                            >
                                当日にご来館
                            </p>
                        </div>
                        <v-img max-height="15px" :src="sankaku" contain></v-img>
                        <p class="under my-4 text-md-h4 font-weight-thin">
                            カウンセリング
                        </p>
                        <v-img max-height="15px" :src="sankaku" contain></v-img>
                        <p class="under my-4 text-md-h4 font-weight-thin">
                            体力チェック
                        </p>
                        <v-img max-height="15px" :src="sankaku" contain></v-img>
                        <p class="under my-4 text-md-h4 font-weight-thin">
                            メニュー提案
                        </p>
                        <v-img max-height="15px" :src="sankaku" contain></v-img>
                        <p class="under my-4 text-md-h4 font-weight-thin">
                            トレーニング体験
                        </p>
                        <v-img max-height="15px" :src="sankaku" contain></v-img>
                        <p class="under my-4 text-md-h4 font-weight-thin">
                            食事アドバイス
                        </p>
                        <v-img max-height="15px" :src="sankaku" contain></v-img>
                        <p class="under my-4 text-md-h4 font-weight-thin">
                            規約説明・入会のご案内
                        </p>
                        <v-img max-height="15px" :src="sankaku" contain></v-img>
                        <div>
                            <p
                                class="blue-grey--text my-4 text-md-h4 font-weight-thin"
                            >
                                入会希望の方はお手続き
                            </p>
                        </div>
                    </div>
                </v-col>
            </v-row>

            <v-row justify="center" :class="smAndUp ? 'mb-15' : null">
                <v-col cols="12" align="center">
                    <div
                        :class="smAndUp ? 'text-h4 mb-10 ' : 'text-h6'"
                        class="font-weight-thin"
                        v-html="goods"
                    ></div>
                </v-col>
                <v-col cols="12" md="5" class="outer-goods">
                    <div class="blue-grey lighten-5 ma-auto goods">
                        <p
                            :class="smAndUp ? 'text-body-1' : 'text-body-2'"
                            class="ma-4"
                        >
                            ① 運動できる格好（Tシャツ / ズボン / 靴下）
                        </p>
                        <p
                            :class="smAndUp ? 'text-body-1' : 'text-body-2'"
                            class="ma-4"
                        >
                            ② 体験料：5,000円（税込）
                        </p>
                        <p
                            :class="smAndUp ? 'text-body-1' : 'text-body-2'"
                            class="ma-4"
                        >
                            ③
                            キャッシュカード（月会費支払い用の指定口座）と銀行届出印鑑
                        </p>
                    </div>
                </v-col>
            </v-row>

            <v-row justify="center" :class="smAndUp ? 'mb-15' : null">
                <v-col cols="12" align="center">
                    <div
                        :class="smAndUp ? 'text-h4 mb-10 ' : 'text-h6'"
                        class="apply font-weight-thin"
                    >
                        体験のお申込みはこちら
                    </div>
                </v-col>
                <v-col cols="12" align="center">
                    <div
                        :class="smAndUp ? 'text-h4 mb-10 ' : 'text-h6'"
                        class="font-weight-thin"
                        v-html="line"
                    ></div>
                </v-col>
                <v-row>
                    <v-col class="ma-auto" cols="12" md="8">
                        <div>
                            LINEの友達追加ボタンから友達登録していただき、ご希望の店舗と体験のご希望の曜日や時間帯の候補をお知らせください。
                            ご質問も受け付けております。できるだけすぐにご返答いたします！
                        </div>
                    </v-col>
                </v-row>
                <v-col cols="12" md="3">
                    <div
                        :class="smAndUp ? 'text-h4 mb-10 ' : 'text-h6'"
                        class="green--text font-weight-thin"
                    >
                        Step 1
                    </div>
                    <div
                        :class="smAndUp ? 'text-body-1' : 'text-body-2'"
                        class="line-flow"
                    >
                        こちらのリンクからレボイストを友だち追加。
                        <div class="ma-4" align="center">
                            <div v-if="smAndUp" class="ma-2 text-body-1">
                                こちらをクリック
                            </div>
                            <div v-if="smAndUp" class="mb-2">▼</div>
                            <a href="https://lin.ee/bFpWQ0A"
                                ><img
                                    height="48"
                                    border="0"
                                    src="https://scdn.line-apps.com/n/line_add_friends/btn/ja.png"
                            /></a>
                        </div>
                    </div>
                </v-col>
                <v-col cols="12" md="3">
                    <div
                        :class="smAndUp ? 'text-h4 mb-10 ' : 'text-h6'"
                        class="green--text font-weight-thin"
                    >
                        Step 2
                    </div>
                    <div
                        :class="smAndUp ? 'text-body-1' : 'text-body-2'"
                        class="line-flow"
                    >
                        メッセージに対してご返信ください。
                    </div>
                    <v-img
                        contain
                        :class="smAndUp ? 'ma-auto ' : 'mx-4'"
                        :src="contact1"
                    ></v-img>
                </v-col>
                <v-col cols="12" md="3">
                    <div
                        :class="smAndUp ? 'text-h4 mb-10 ' : 'text-h6'"
                        class="green--text font-weight-thin"
                    >
                        Step 3
                    </div>
                    <div
                        :class="smAndUp ? 'text-body-1' : 'text-body-2'"
                        class="line-flow"
                    >
                        ご希望の店舗やお時間を決めていただきます。
                    </div>
                    <v-img
                        :class="smAndUp ? 'ma-auto ' : 'mx-4 contain'"
                        :src="contact2"
                    ></v-img>
                </v-col>
                <v-col cols="12" md="3">
                    <div
                        :class="smAndUp ? 'text-h4 mb-10 ' : 'text-h6'"
                        class="green--text font-weight-thin"
                    >
                        Step 4
                    </div>
                    <div
                        :class="smAndUp ? 'text-body-1' : 'text-body-2'"
                        class="line-flow"
                    >
                        ご予約日に体験にお越しいただきます。
                    </div>
                    <v-img
                        contain
                        :class="smAndUp ? 'ma-8 mt-0 ' : 'ma-auto'"
                        :src="contact3"
                        max-height="25vh"
                    ></v-img>
                </v-col>
            </v-row>
        </v-container>

        <footer-contents></footer-contents>
    </div>
</template>

<script>
import Heading from '@/components/Heading.vue'
import FooterContents from '@/components/FooterContents.vue'
export default {
    mounted() {
        document.title = `${this.$route.meta.title} | パーソナルトレーニングジム【REVOISTレボイスト】`
    },
    components: {
        Heading,
        FooterContents,
    },

    computed: {
        smAndUp() {
            return this.$vuetify.breakpoint.smAndUp
        },
        breadCrumbs() {
            return [
                {
                    text: 'トップ',
                    disabled: false,
                    href: './',
                },
                {
                    text: 'よくあるご質問',
                    disabled: true,
                    href: 'faq',
                },
            ]
        },
        imageMv() {
            // TODO: spはウォーターマークあり
            return this.$vuetify.breakpoint.smAndUp
                ? 'static/pc_contact/pixta_35383873_XL.png'
                : 'static/sp_contact/contact_img01.png'
        },
        lead() {
            return '体験の<span class="red--text">流れ</span>'
        },
        goods() {
            return '体験時の<span class="red--text">持ち物</span>'
        },
        sankaku() {
            return 'static/pc_contact/sankaku.svg'
        },
        line() {
            return '<span class="green--text">LINE</span>で簡単にお申し込み！'
        },
        contact1() {
            return this.$vuetify.breakpoint.smAndUp
                ? 'static/pc_contact/IMG_contact_1@2x.png'
                : 'static/sp_contact/contact_img02@2x.png'
        },
        contact2() {
            return this.$vuetify.breakpoint.smAndUp
                ? 'static/pc_contact/IMG_contact_2@2x.png'
                : 'static/sp_contact/contact_img03@2x.png'
        },
        contact3() {
            return this.$vuetify.breakpoint.smAndUp
                ? 'static/pc_contact/pixta_24685771_XL@2x.png'
                : 'static/sp_contact/contact_img04@2x.png'
        },
    },
}
</script>

<style lang="scss">
.q-color {
    color: #1a0dab;
}
.a-color {
    color: #c30013;
}
.under {
    border-bottom: dotted 2px #e0e0e0;
}
.steps {
    /* 連番カウンター名の定義 */
    counter-reset: step-counter;
    /* 縦棒との位置関係に必要 */
    position: relative;
    /* 縦棒と連番のためのスペースを左に確保 */
    padding-left: 4rem; /* 連番(1.5rem) + 余白 */
}
.steps > p {
    /* 連番カウンターを+1する */
    counter-increment: step-counter;
}

.steps > p:before {
    /* 連番カウンターの値を表示する */
    content: counter(step-counter);
    /* フォントと色 */
    color: red;
    font-size: 1rem;
    font-weight: normal;
    /* 文字を中央に表示する */
    line-height: 2.2rem;
    text-align: center;
    /* 円で表示する */
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 4rem;
    border: solid 1px red;
    /* .stepsでmargin-left +2rem したぶん左に戻す */
    position: absolute;

    left: 0;
    /* 縦棒より手前に表示するため */
    z-index: 1;
}

.steps-sp {
    /* 連番カウンター名の定義 */
    counter-reset: step-counter;
    /* 縦棒との位置関係に必要 */
    position: relative;
    /* 縦棒と連番のためのスペースを左に確保 */
    padding-left: 2rem; /* 連番(1.5rem) + 余白 */
}
.steps-sp > p {
    /* 連番カウンターを+1する */
    counter-increment: step-counter;
}

.steps-sp > p:before {
    /* 連番カウンターの値を表示する */
    content: counter(step-counter);
    /* フォントと色 */
    color: red;
    font-size: 0.8rem;
    font-weight: normal;
    /* 文字を中央に表示する */
    line-height: 1.2rem;
    text-align: center;
    /* 円で表示する */
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 4rem;
    border: solid 1px red;
    /* .stepsでmargin-left +2rem したぶん左に戻す */
    position: absolute;

    left: 0;
    /* 縦棒より手前に表示するため */
    z-index: 1;
}
.outer-goods {
    display: table;
    width: 100%;
    min-height: 15vh;
    .goods {
        display: table-cell;
        vertical-align: middle;
    }
}
.apply {
    color: white;
    background-color: #00b900;
    min-height: 10vh;

    display: flex;
    justify-content: center; /*左右中央揃え*/
    align-items: center; /*上下中央揃え*/
}
.line-flow {
    min-height: 7vh;
}
</style>
